<script setup lang="ts">
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth.module";
import { type Institution } from "@/graphql/generated-types";
import { type SimpleItem } from "@/interfaces";
import DropdownTrigger from "@/stories/buttons/DropdownTrigger/DropdownTrigger.vue";
import DropdownLinkBuilder from "@/stories/buttons/DropdownButton/DropdownLinkBuilder.vue";
import TextInput from "@/stories/form/TextInput/TextInput.vue";
import { useBreakpoints } from "@vueuse/core";
import { BREAKPOINTS } from "@/consts";

const authStore = useAuthStore();
const searchText = ref("");
const breakpoints = useBreakpoints(BREAKPOINTS);
const isMobile = breakpoints.smallerOrEqual("tablet");
const activeSelection = ref();

const institutions = computed(() => {
  if (!authStore.profile?.institutions) return [];

  return authStore.profile?.institutions
    .map((institution) => {
      return {
        title: institution.name,
        value: institution.id,
      } as SimpleItem;
    })
    .toSorted((a: SimpleItem, b: SimpleItem) =>
      a.title.localeCompare(
        b.title,
        authStore.currentInstitution?.locale || "da",
      ),
    )
    .toSorted((a: SimpleItem) =>
      a.value === authStore.institutionId ? -1 : 1,
    );
});

const filteredInstitutions = computed(() => {
  return institutions.value.filter((institution) =>
    institution.title
      .toLocaleLowerCase()
      .includes(searchText.value.toLocaleLowerCase()),
  );
});

const selectedItem = computed(() => {
  // if on mobile we should save the active selection
  if (activeSelection.value) return activeSelection.value;

  // on web we just return the current institution
  return filteredInstitutions.value.find(
    (institution) => institution.value == authStore?.currentInstitution?.id,
  );
});

function switchInstitution(selectedItem: SimpleItem) {
  const selectedInstitution = authStore.profile?.institutions.find(
    (institution) => institution.id === selectedItem.value,
  ) as Institution;

  if (
    !selectedInstitution ||
    selectedInstitution.id === authStore?.currentInstitution?.id
  ) {
    return;
  }

  authStore.switchInstitution(selectedInstitution.id);
}

function handleSelect(selectedItem: SimpleItem) {
  // On web we should switch institution on click, but in the mobile drawer the user needs to click save first
  if (isMobile.value === true) {
    activeSelection.value = selectedItem;
    return;
  }

  switchInstitution(selectedItem);
}

const closeList = () => {
  searchText.value = "";
};
</script>

<template>
  <DropdownTrigger
    placement="bottomRight"
    @click-outside="closeList"
    @close="closeList"
    @save="switchInstitution(selectedItem)"
    :is-mobile-drawer="isMobile"
    :full-width="isMobile"
  >
    <template #trigger="{ isActive }">
      <button
        ref="buttonRef"
        type="button"
        class="h300 text-neutral1000 flex gap-2 items-center relative transition-colors border focus:outline-neutral1000 dropdown-button px-3 py-1.5 p-3 justify-between rounded rounded-full"
        :class="[
          {
            'w-full': true,
            'hover:bg-neutral100': !isActive,
            'bg-darkOcean100 outline outline-2 outline-darkOcean600 focus:outline-darkOcean600':
              isActive,
          },
        ]"
      >
        <i class="far fa-repeat"></i>

        <div class="flex justify-between w-full">
          <div class="flex-grow text-left truncate">
            {{ authStore.currentInstitution?.name }}
          </div>
        </div>

        <i
          class="far fa-chevron-down transition-transform text-xl"
          :class="{
            'rotate-180': isActive,
          }"
        ></i>
      </button>
    </template>

    <template #content>
      <div
        class="rounded-lg px-2 flex flex-col gap-1 z-10 overflow-y-scroll max-h-[600px] pb-2 dropdown-trigger-no-close"
        :class="isMobile ? 'w-full' : 'min-w-[400px]'"
      >
        <div class="bg-neutral0 sticky top-0">
          <TextInput
            ref="searchRef"
            name="search"
            rounded
            size="sm"
            icon="fa fa-search"
            background="bg-neutral100"
            :placeholder="$t('global.searchForInstitution')"
            class="pt-2 pb-2 relative"
            v-model="searchText"
            @click="$event.stopPropagation()"
          />
        </div>

        <DropdownLinkBuilder
          v-for="(item, index) of filteredInstitutions"
          :key="index"
          :item="item"
          :isRadio="true"
          :interval-value="selectedItem?.value"
          @click="() => handleSelect(item)"
        >
          {{ item.title }}
        </DropdownLinkBuilder>

        <div
          v-if="filteredInstitutions.length === 0"
          class="b300 text-neutral700 text-center py-2"
        >
          {{ $t("global.noData") }}
        </div>
      </div>
    </template>
  </DropdownTrigger>
</template>
