import { graphqlClient } from "@/apollo";
import {
  GetDepartmentListDocument,
  type GetDepartmentListQuery,
} from "@/graphql/generated-types";
import { GraphQLError } from "graphql";

const getDepartments = async (): Promise<GetDepartmentListQuery> => {
  const response = await graphqlClient.query({
    query: GetDepartmentListDocument,
    fetchPolicy: "no-cache",
  });

  if (response.data.institutions?.getMyInstitution.departments) {
    return response.data;
  }

  throw new GraphQLError(`api.departmets.getDepartments`);
};

export default {
  getDepartments,
};
