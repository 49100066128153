import activities from "./activities";
import departmets from "./departmets";
import employees from "./employees";
import news from "./news";
import notifications from "./notifications";
import planner from "./planner";
import profile from "./profile";
import relativeProfile from "./relativeProfile";
import resource from "./resource";
import user from "./user";
import institution from "./institution";
import categories from "./categories";

export default {
  activities,
  departmets,
  employees,
  news,
  notifications,
  planner,
  profile,
  relativeProfile,
  resource,
  user,
  institution,
  categories,
};
