import type { IValidationResult } from "@/interfaces";
import type Joi from "joi";
import { type ValidationErrorItem } from "joi";
import { useI18n } from "vue-i18n";

// For all messages keys, we need to define an error message in translations
const MESSAGE_KEYS: string[] = [
  "any.required",
  "array.length",
  "array.max",
  "array.min",
  "number.greater",
  "number.less",
  "number.max",
  "number.min",
  "string.alphanum",
  "string.email",
  "string.empty",
  "string.length",
  "string.max",
  "string.min",
];

const MESSAGES: Record<string, string> = {};

export const validate = (
  schema: Joi.ObjectSchema<any>,
  data: any,
  customMessages: Record<string, string> = {},
): IValidationResult => {
  const validation = schema.validate(data, {
    messages: {
      ...MESSAGES, // Translated error messages
      ...customMessages, // We can use custom error message for specific cases
    },
    abortEarly: false, // We want to show all message validations
    allowUnknown: true, // We allow the partly validation
  });
  const messages: Record<string, string> = {};

  if (validation.error) {
    validation.error?.details.forEach((item: ValidationErrorItem) => {
      // We need to keep the path as a string to access the error from child
      // components. For example: "pdf.0.name"
      // We can use this structure for lists
      const path = item.path.join(".");

      if (item.context?.key) {
        messages[path] = item.message;
      }
    });
  }

  const isValid = !validation.error;

  if (!isValid && import.meta.env.DEV) {
    console.warn(new Error("Validation error"), messages);
  }

  return {
    isValid,
    errors: messages,
  };
};

export const fixValidationFields = (
  validation: IValidationResult,
  fields: Record<string, string>,
) => {
  if (validation.isValid) {
    return validation;
  }

  for (const key in validation.errors) {
    let message = validation.errors[key];

    for (const field of Object.keys(fields)) {
      message = message.replaceAll(`ref:${field}`, `"${fields[field]}"`);
    }

    validation.errors[key] = message;
  }

  return validation;
};

// This setup function should be called once from App.vue. It loads the
// translations values, and fix parameter types for JOI. This function should be
// called at least once. But, it should be called again after the language is
// changed.
export const validationSetup = () => {
  const { t } = useI18n();

  MESSAGE_KEYS.forEach((key) => {
    // JOI expect {{#limit}} format. But vue-i18n doesn't accept that format.
    // Because of that, I've created our format: ((#limit)). vue-i18n accepts
    // our format. But after the getting the translation value, we should set
    // the JOI's format.
    // We can NOT remove .replace() functions here.
    MESSAGES[key] = t(`validations.${key}`)
      .replace(/\(\(/g, "{{")
      .replace(/\)\)/g, "}}");
  });
};
