import { createApp, h } from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import App from "@/App.vue";
import router from "./router";
import moment from "moment";
// import { makeServer } from "./server";
import "@/focus-visible";
import FloatingVue from "floating-vue";
import emitter from "./emitter";
import clickoutside from "@/directives/clickoutside";
import i18n from "./i18n";
import { createProvider, filesRoot } from "./vue-apollo";
import setup from "@/services/apiclient/interceptors";
moment.locale("da");

import "./main.scss";
import "floating-vue/dist/style.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "sweetalert2/src/sweetalert2.scss";
import "@/stories/styles.scss";
import { setStores } from "./stores";
import { sentryInit } from "./sentry";
import type { IFeaturesFlag } from "./interfaces";

const app = createApp({
  render: () => h(App),
});

// WCAG-Pikaday need the moment library on Vite
window.moment = moment;

app.config.globalProperties.$filesRoot = filesRoot;
app.config.globalProperties.$mitt = emitter;
// if (import.meta.env.NODE_ENV === "development") {
//   makeServer({
//     environment: import.meta.env.NODE_ENV,
//     mode: import.meta.env.VITE_APP_MIRAGE_MODE || "local"
//   });
// }

const pinia = createPinia();

// We should set the stores map. Check the function details
setStores(pinia);

app.use(PiniaVuePlugin);
app.use(FloatingVue, {
  themes: {
    "borger-default": {
      $extend: "tooltip",
    },
  },
});

app.directive("clickoutside", clickoutside);

setup();

// Set feature flags from query string.
(function () {
  const regex = /[&?]([\w]+)=(?:1|yes|true)/g;
  let match;
  const flags: Record<any, boolean> = {};
  while ((match = regex.exec(window.location.search))) {
    flags[match[1]] = true;
  }
  app.config.globalProperties.$features = flags as IFeaturesFlag;
  window.$features = flags as IFeaturesFlag;
})();

sentryInit(app, router);

app.use(router);
app.use(createProvider());
app.use(i18n);
app.use(pinia);
app.mount("#app");
