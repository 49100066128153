<script setup lang="ts">
import { type IValidationResult } from "@/interfaces";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
  name: string;
  validation?: IValidationResult | null;
  errorMessageKey?: string;
}

const props = defineProps<Props>();

const { name, validation, errorMessageKey } = toRefs(props);

const { t } = useI18n();

const errorMessage = computed(() => {
  if (errorMessageKey.value) {
    return t(errorMessageKey.value);
  }

  if (!validation.value) {
    return undefined;
  }

  if (validation.value.isValid) {
    return undefined;
  }

  const field = validation.value.errors[name.value];
  if (!field) {
    return undefined;
  }

  return field;
});
</script>

<template>
  <div v-if="errorMessage" class="mt-1 text-red400 b300">
    {{ errorMessage }}
  </div>
</template>
