import { graphqlClient } from "@/apollo";
import { type InstitutionBasic } from "@/extendedTypes";
import { InstitutionQueryDocument } from "@/graphql/generated-types";

const getInstitution = async (id: string) => {
  try {
    const response = await graphqlClient.query({
      query: InstitutionQueryDocument,
      variables: {
        ids: [id],
      },
    });

    if (!response.data?.institutions?.get) {
      throw new Error("An error ocurred when getting institution data");
    }

    return response.data.institutions.get[0] as InstitutionBasic;
  } catch (error) {
    console.error("getInstitution error", error);
  }
};

export default {
  getInstitution,
};
