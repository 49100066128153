<script setup lang="ts">
import {
  onMounted,
  ref,
  watch,
  defineEmits,
  defineProps,
  withDefaults,
} from "vue";
import { nanoid } from "nanoid";
import { onClickOutside } from "@vueuse/core";

const show = ref(false);

const triggerBoxId = `trigger-box-${nanoid()}`;

interface Props {
  isActive: boolean;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
});

const emit = defineEmits<{
  click: [];
  save: [];
  "click-outside": [];
  close: [];
}>();

const drawerRef = ref();

onMounted(async () => {
  setTimeout(() => {
    show.value = true;
  }, 200);
});

const close = () => {
  show.value = false;

  setTimeout(() => {
    emit("close");
  }, 500);
};

onClickOutside(drawerRef, (element) => {
  const clickedElement = element.target as HTMLElement;

  if (clickedElement.closest(`#${triggerBoxId}`) === null) {
    close();
  }

  close();
});

watch(
  () => props.isActive,
  () => {
    if (props.isActive === false) {
      close();
    }
  },
);
</script>

<template>
  <transition
    enter-active-class="transition-all duration-500 ease-out"
    enter-from-class="translate-y-full"
    enter-to-class="translate-y-0"
    leave-active-class="transition-all duration-500 ease-in"
    leave-from-class="translate-y-0"
    leave-to-class="translate-y-full"
  >
    <div
      v-show="show"
      ref="drawerRef"
      class="bg-white p-2 flex flex-col gap-1 min-w-140 z-50 h-fit shadow-lg absolute rounded-lg border border-neutral100 fixed bottom-0 left-0 w-full max-h-[93dvh] rounded-tl-3xl rounded-tr-3xl border border-neutral300"
    >
      <div
        class="relative flex justify-center items-center h500 text-neutral1000 py-4 border-b border-neutral300 -ml-2 -mr-2 mt-2"
      >
        <div>{{ title }}</div>
        <button
          type="button"
          class="absolute right-5 text-neutral1000 w-10 h-10 mb-2"
          @click="close"
        >
          <i class="fas fa-times text-2xl" />
        </button>
      </div>
      <slot name="content"></slot>
    </div>
  </transition>
</template>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.6s ease-out;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100vh);
}
.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
}
</style>
