<script setup lang="ts">
import { type PlacementTypes } from "@/types";
import { BREAKPOINTS } from "@/consts";
import { useBreakpoints } from "@vueuse/core";
import Button from "../Button/Button.vue";

interface Props {
  isMobileDrawer?: boolean;
  title?: string;
  placement?: PlacementTypes;
  fullWidth?: boolean;
}

withDefaults(defineProps<Props>(), {
  isMobileDrawer: false,
  placement: "bottom",
  fullWidth: false,
});

const emit = defineEmits<{
  click: [MouseEvent];
  cancel: [];
  save: [];
}>();

const breakpoints = useBreakpoints(BREAKPOINTS);
const isTablet = breakpoints.greaterOrEqual("tablet");

const PLACEMENT_MAPS = {
  bottom: "left-0",
  bottomLeft: "left-0",
  bottomRight: "right-0",
  top: "left-0",
  topLeft: "left-0",
  topRight: "right-0",
};
</script>

<template>
  <div
    ref="menuRef"
    class="bg-white p-2 flex flex-col gap-1 min-w-140 z-50 h-fit shadow-lg"
    :class="{
      'absolute rounded-lg border border-neutral100':
        isTablet || !isMobileDrawer,
      [PLACEMENT_MAPS[placement || 'bottom']]: isTablet || !isMobileDrawer,
      'fixed bottom-0 left-0 w-full max-h-[93dvh] rounded-tl-3xl rounded-tr-3xl border border-neutral300':
        !isTablet && isMobileDrawer,
      'border border-neutral200 rounded-lg': !isTablet && !isMobileDrawer,
      'w-full': fullWidth,
    }"
    @click="(event) => $emit('click', event)"
  >
    <div
      v-if="!isTablet && isMobileDrawer"
      class="relative flex justify-center items-center h500 text-neutral1000 py-4 border-b border-neutral300 -ml-2 -mr-2"
      :class="[isMobileDrawer ? 'mt-2' : 'py-4']"
    >
      <div v-if="title">{{ title }}</div>
      <button
        type="button"
        class="absolute right-5 text-neutral1000 w-10 h-10 mb-2"
        @click="emit('cancel')"
        :full-width="fullWidth"
      >
        <i class="fas fa-times text-2xl" />
      </button>
    </div>
    <slot></slot>
    <div
      v-if="!isTablet && isMobileDrawer"
      class="border-t border-neutral300 -ml-2 -mr-2 py-3 pb-4 px-4"
    >
      <Button
        :text="$t('global.save')"
        class="whitespace-nowrap"
        full-width
        size="lg"
        @click="$emit('save')"
      />
    </div>
  </div>
</template>
