import { graphqlClient } from "@/apollo";
import {
  GetCategoriesDocument,
  type CategoryInput,
  SaveCategoryDocument,
  Category_Type,
} from "@/graphql/generated-types";
import { useAuthStore } from "@/stores/auth.module";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const getCategories = async () => {
  const authStore = useAuthStore();
  const institutionId = authStore.institutionId;

  const response = await graphqlClient.query({
    query: GetCategoriesDocument,
    variables: {
      institutionId,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.getCategories) {
    return response.data.activity.getCategories;
  }

  throw new SentryGraphQLError(`api.categories.getCategories`);
};

const save = async (category: CategoryInput) => {
  const response = await graphqlClient.query({
    query: SaveCategoryDocument,
    variables: {
      category,
      type: "ACTIVITY" as Category_Type,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.category?.save?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.categories.save`,
    response.data.category?.save,
  );
};

const deleteItem = async (id: string) => {
  console.log("Coming later", id);
};

export default {
  getCategories,
  save,
  deleteItem,
};
