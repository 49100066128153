<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";
import DropdownMenuContainer from "../DropdownButton/DropdownMenuContainer.vue";
import { type PlacementTypes } from "@/types";
import { nanoid } from "nanoid";
import { onClickOutside, useBreakpoints } from "@vueuse/core";
import { BREAKPOINTS } from "@/consts";
import MobileDrawer from "@/stories/displays/MobileDrawer/MobileDrawer.vue";

const triggerBoxId = `trigger-box-${nanoid()}`;

interface Props {
  label?: string;
  placement?: PlacementTypes;
  isMobileDrawer?: boolean;
  fullWidth?: boolean;
  isActive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  placement: "bottom",
  isMobileDrawer: false,
  fullWidth: false,
  isActive: false,
});

const emit = defineEmits<{
  click: [];
  save: [];
  "click-outside": [];
  close: [];
}>();

const { placement } = toRefs(props);
const menuRef = ref();
const trigger = ref<Element | null>(null);
const triggerHeight = ref(0);

const breakpoints = useBreakpoints(BREAKPOINTS);
const isTablet = breakpoints.greaterOrEqual("tablet");
const show = ref(props.isActive);

const handleClick = () => {
  show.value = !show.value;
  emit("click");
};

onMounted(async () => {
  const triggerBox = document.getElementById(triggerBoxId);

  if (!triggerBox) {
    return;
  }

  trigger.value = triggerBox.firstElementChild;

  if (!trigger.value) {
    return;
  }

  // We should wait for a until the trigger is be ready
  await new Promise((resolve) => setTimeout(resolve, 100));

  triggerHeight.value = trigger.value.getBoundingClientRect().height + 4;

  trigger.value.addEventListener("click", handleClick);
});

onBeforeUnmount(() => {
  if (!trigger.value) {
    return;
  }

  trigger.value.removeEventListener("click", handleClick);
});

const handleContentClick = (event: MouseEvent) => {
  const clickedElement = event.target as HTMLElement;
  if (clickedElement.closest(".dropdown-trigger-no-close")) {
    return;
  }

  close();
};

onClickOutside(menuRef, (element) => {
  const clickedElement = element.target as HTMLElement;

  if (clickedElement.closest(`#${triggerBoxId}`) === null) {
    close();
  }

  emit("click-outside");
});

const customStyle = computed(() => {
  if (!isTablet.value) {
    return;
  }

  const bottomPlacements: PlacementTypes[] = [
    "bottom",
    "bottomLeft",
    "bottomRight",
  ];

  if (bottomPlacements.includes(placement.value)) {
    return {
      top: `${triggerHeight.value}px`,
    };
  }

  return {
    bottom: `${triggerHeight.value}px`,
  };
});

const close = () => {
  show.value = false;
  emit("close");
};

watch(
  () => props.isActive,
  () => {
    show.value = props.isActive;
    if (props.isActive === false) {
      close();
    }
  },
);
</script>

<template>
  <div class="relative" :class="{ 'w-fit': !fullWidth }">
    <span
      :id="triggerBoxId"
      class="w-fit inline-block text-nowrap dropdown-trigger-box"
      :class="[
        fullWidth ? 'w-full' : 'w-fit',
        { 'is-trigger-active': isActive },
      ]"
    >
      <slot name="trigger" :is-active="isActive"></slot>
    </span>
    <div
      v-if="!isTablet && show && isMobileDrawer"
      class="bg-blacka fixed left-0 top-0 w-full h-full z-50"
    >
      <MobileDrawer
        :is-active="isActive"
        v-if="isMobileDrawer"
        @close="close"
        @click-outside="emit('close')"
        :title="label"
        class="opacity-1"
      >
        <template #content>
          <slot name="content"></slot>
        </template>
      </MobileDrawer>
    </div>

    <DropdownMenuContainer
      v-else-if="show && !isMobileDrawer"
      ref="menuRef"
      :title="label"
      :isMobileDrawer="isMobileDrawer"
      :style="customStyle"
      :placement="placement"
      @click="handleContentClick"
      @save="$emit('save')"
      :class="{ 'w-full': fullWidth }"
    >
      <slot name="content"></slot>
    </DropdownMenuContainer>
  </div>
</template>
