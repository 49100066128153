import { graphqlClient } from "@/apollo";
import {
  type ResourceInput,
  SaveResourceDocument,
} from "@/graphql/generated-types";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const save = async (resource: ResourceInput) => {
  try {
    const response = await graphqlClient.query({
      query: SaveResourceDocument,
      variables: {
        resource,
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.resource?.save?.success) {
      return response.data.resource.save.id;
    }

    throw new SentryGraphQLError(
      `api.resource.save`,
      response.data.resource?.save,
    );
  } catch (error) {
    console.error("Error while creating new resource: ", error);
    throw error;
  }
};

export default {
  save,
};
