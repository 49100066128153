import { APP_API_URL } from "../consts";
import { graphqlClient } from "@/apollo";
import {
  DeleteUserDocument,
  type Employee,
  GetSelfQueryDocument,
  GetUserStatisticsDocument,
  SaveUserDocument,
  type UserSaveInput,
} from "@/graphql/generated-types";
import { type EmployeeExtended, type InstitutionBasic } from "@/extendedTypes";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const getToken = async (jwt: string) => {
  const response = await window.fetch(`${APP_API_URL}/user/accesstoken`, {
    method: "GET",
    headers: {
      Authorization: jwt,
    },
  });

  if (response.status === 200) {
    return await response.json();
  }

  throw new Error(`Auth token error.`);
};

const getSelf = async (): Promise<EmployeeExtended> => {
  const response = await graphqlClient.query({
    query: GetSelfQueryDocument,
    variables: {},
    fetchPolicy: "no-cache",
  });

  if (response.data.self?.get.profiles) {
    const [firstProfile] = response.data.self.get.profiles;

    return {
      ...(firstProfile as Employee),
      institutions: response.data.self.institutions as InstitutionBasic[],
    };
  }

  throw new SentryGraphQLError(`api.user.getSelf`);
};

const save = async (user: UserSaveInput) => {
  const response = await graphqlClient.query({
    query: SaveUserDocument,
    variables: {
      user,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveUser?.success) {
    return response.data.user.saveUser.id;
  }

  throw new SentryGraphQLError(`api.user.save`, response.data.user?.saveUser);
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteUserDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.deleteUser?.success) {
    return response.data.user.deleteUser.success;
  }

  throw new SentryGraphQLError(
    `api.user.deleteItem`,
    response.data.user?.deleteUser,
  );
};

const getUsersStatistics = async (ids: string[]) => {
  const response = await graphqlClient.query({
    query: GetUserStatisticsDocument,
    variables: {
      ids,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.get) {
    return response.data.user.get;
  }

  throw new Error("GetUsersStatistics query error.");
};

const getUserStatistics = async (id: string) => {
  const response = await getUsersStatistics([id]);
  const [user] = response;
  if (user) {
    return user;
  }

  throw new Error("GetUserStatistics query error.");
};

export default {
  getToken,
  getSelf,
  save,
  deleteItem,
  getUsersStatistics,
  getUserStatistics,
};
