import { useAuthStore } from "./stores/auth.module";

let isLoaded = false;

export async function sentryException(err: any, extra = {}) {
  // We should show the error on development environment
  if (import.meta.env.DEV) {
    console.error(err);
  }

  // If the Sentry is not inited, we shouldn't do anything
  if (isLoaded === false) {
    return;
  }

  const Sentry = await import("@sentry/vue");

  const data: Record<string, any> = {
    graphQLErrors: err?.graphQLErrors,
    ...extra,
  };

  const auth = useAuthStore();

  if (auth) {
    data.userId = auth.id;
  }

  Sentry.withScope((scope: any) => {
    if (data) {
      for (const key in data) {
        scope.setExtra(key, data[key]);
      }
    }

    Sentry.captureException(err);
  });
}

export const toFetchContext = async (response: any) => {
  // We should clone it because we can't use the same json() function twice.
  const status = response.status;
  const contentType = response.headers.get("Content-Type");

  const context = { status, contentType, body: null };

  try {
    context.body = await response.clone().json();
  } catch (error) {
    context.body = await response.clone().text();
  }

  return { HTTPResponse: context };
};

export const sentryInit = async (app: any, router: any) => {
  if (import.meta.env.NODE_ENV === "development") {
    return;
  }

  // Loading the Sentry script dynamically. Because the user should consent
  // for Sentry Analytics cookies.
  const Sentry = await import("@sentry/vue");
  isLoaded = true;

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: false,
    release: import.meta.env.VITE_APP_SENTRY_RELEASE || "vue-manager@unknown",
    environment: import.meta.env.VITE_APP_SENTRY_ENV || "development",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.vueIntegration({ tracingOptions: { trackComponents: true } }),
    ],

    tracePropagationTargets: [
      "/^https://ibgmanager.dk/]",
      "ibg.test",
      "ibgmanager.test",
      "api.ibg.test",
      /^https:\/\/ibgmanager\.dk/,
      /^https:\/\/ibg\.proreact\.dk/,
      /^https:\/\/api\.ibgmanager\.dk/,
      /^https:\/\/staging\.ibgmanager\.dk/,
      /^https:\/\/api\.staging\.ibgmanager\.dk/,
      /^\//,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.NODE_ENV === "production" ? 0.05 : 1,
    attachProps: true,
    attachStacktrace: true,
    initialScope: {
      tags: {
        sentyr_env: import.meta.env.VITE_APP_SENTRY_ENV || "development",
      },
    },
  });
};
