import { graphqlClient } from "@/apollo";
import { type InstitutionsEmployeeFragment } from "@/graphql";
import {
  InstitutionsDocument,
  SaveEmployeeDocument,
  type EmployeeInput,
} from "@/graphql/generated-types";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const getEmployees = async (): Promise<InstitutionsEmployeeFragment[]> => {
  const response = await graphqlClient.query({
    query: InstitutionsDocument,
    fetchPolicy: "no-cache",
  });

  if (response.data.institutions?.getMyInstitution.departments) {
    const { departments } = response.data.institutions.getMyInstitution;
    const institutionEmployees = departments.flatMap((d) => d.employees);
    return institutionEmployees as InstitutionsEmployeeFragment[];
  }

  throw new SentryGraphQLError(`api.employees.getEmployees`);
};

const save = async (employee: EmployeeInput) => {
  const response = await graphqlClient.query({
    query: SaveEmployeeDocument,
    variables: {
      employee,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveProfile?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.employees.save`,
    response.data.user?.saveProfile,
  );
};

export default {
  getEmployees,
  save,
};
