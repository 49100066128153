import { APP_API_URL } from "@/consts";
import { graphqlClient } from "@/apollo";
import {
  SaveProfileDocument,
  SaveUserDocument,
  Container_Type,
  type RelativeInput,
  type UserRoleInput,
  type UserSaveInput,
} from "@/graphql/generated-types";
import save from "@/api/resource";
import { simpleError } from "@/stories/displays/SimpleError/SimpleError.vue";
import type { IFileState } from "@/interfaces";

/**
 * This API service is a part of Relation profile creation.
 * https://proreact.atlassian.net/browse/IBG-7037
 *
 * Example: http://localhost:5173/relative-profile-creation?t=XXXXX
 */

const DOMAIN = `${APP_API_URL}/institution/accesstoken`;

export type CreateUserProfileInput = {
  roles: UserRoleInput[];
} & Partial<UserSaveInput> &
  Partial<RelativeInput>;

const validateToken = async (code: string) => {
  try {
    const res = await fetch(DOMAIN, {
      headers: { Authorization: code },
    });

    if (!res.ok) {
      throw new Error(`API error: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Token validation failed:", error);
    return null;
  }
};

const createUserProfile = async (
  createUserProfileInput: CreateUserProfileInput,
  profileImage: IFileState,
) => {
  const {
    email,
    password,
    roles,
    institution,
    mobile,
    lastname,
    firstname,
    relationTo,
    relationType,
    commentRelative,
  } = createUserProfileInput;

  try {
    const { data } = await graphqlClient.query({
      query: SaveUserDocument,
      variables: {
        user: {
          email,
          username: email,
          password,
          roles,
        },
      },
      fetchPolicy: "no-cache",
    });

    const userId = data.user?.saveUser?.id;

    const profileImageResourceId = await save.save({
      body: profileImage.base64,
      institution,
      container: Container_Type.People,
    });

    return await createProfile({
      image: profileImageResourceId,
      email,
      mobile,
      lastname,
      user: userId,
      firstname,
      relationTo,
      institution,
      relationType,
      commentRelative,
    });
  } catch (error) {
    console.error("Error while creating user", error);
    simpleError("global.error_api");
    throw error;
  }
};

const createProfile = async (relativeInput: RelativeInput) => {
  const response = await graphqlClient.query({
    query: SaveProfileDocument,
    variables: {
      relative: relativeInput,
    },
    fetchPolicy: "no-cache",
  });
  return response;
};

export default {
  createUserProfile,
  validateToken,
};
