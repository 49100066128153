import { graphqlClient } from "@/apollo";
import {
  GetActivitiesByRangeDocument,
  type GetActivitiesByRangeQueryVariables,
  SaveActivityDocument,
  DeleteActivityDocument,
  type ActivityInput,
} from "@/graphql/generated-types";
import { useAuthStore } from "@/stores/auth.module";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

type VisibilityOption = "visibility-on" | "visibility-off";

const getActivities = async (
  dateFrom: string,
  dateTo: string,
  visibility: VisibilityOption,
) => {
  const authStore = useAuthStore();
  const institutionId = authStore.institutionId;

  if (!institutionId) {
    throw new Error("Query error - no institution id provided");
  }

  const variables: GetActivitiesByRangeQueryVariables = {
    dateFrom,
    dateTo,
    filter: {
      departments: [],
      groups: [],
      institutions: [institutionId],
    },
    visibility: visibility === "visibility-on",
  };

  const response = await graphqlClient.query({
    query: GetActivitiesByRangeDocument,
    variables,
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.getActivitiesByFilters) {
    return response.data.activity.getActivitiesByFilters;
  }

  throw new SentryGraphQLError(`api.activities.getActivities`);
};

const save = async (activity: ActivityInput) => {
  const response = await graphqlClient.query({
    query: SaveActivityDocument,
    variables: {
      activity,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.save?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.activities.save`,
    response.data.activity?.save,
  );
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteActivityDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.delete?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.activities.delete`,
    response.data.activity?.delete,
  );
};

export default {
  getActivities,
  save,
  deleteItem,
};
