<script setup lang="ts">
import type { SizeTypes } from "@/types";
import InputWrapper from "../InputWrapper/InputWrapper.vue";
import { nanoid } from "nanoid";
import type { DefaultInputProps } from "@/interfaces";
import { toRefs } from "vue";
import { useFieldValidationError } from "@/composables/useFieldValidationError";

const id = nanoid();
type InputTypes = "text" | "password" | "email";

interface Props extends Omit<DefaultInputProps, "label"> {
  label?: string;
  labelRight?: string;
  type?: InputTypes;
  size?: SizeTypes;
  modelValue?: string | number;
  icon?: string;
  iconComponent?: object;
  rounded?: boolean;
  maxlength?: number;
  background?: string;
  customClasses?: string;
  numberOnly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: "text",
  size: "md",
  background: "bg-neutral0",
  customClasses: "",
  numberOnly: false,
});

const { name, validation, numberOnly } = toRefs(props);

const emit = defineEmits(["update:modelValue", "blur"]);

const SIZE_STYLES = {
  sm: "py-2 px-4",
  md: "p-4",
  lg: "p-5",
};

const handleInput = (event: Event) => {
  const target = event.target as any;

  if (validation.value && validation.value.errors) {
    delete validation.value.errors[name.value];
  }

  // Only allow numbers but without using type number
  if (numberOnly.value) {
    if (!/^\d*$/.test(target.value) || !target.value) {
      target.value = 0;
    }

    emit("update:modelValue", parseInt(target.value));
    return;
  }

  emit("update:modelValue", (event.target as HTMLInputElement).value);
};

const { hasError } = useFieldValidationError(name, validation);
</script>

<template>
  <InputWrapper
    :forId="id"
    :label="label"
    :description="description"
    :isRequired="isRequired"
    :inputKey="inputKey"
    :tooltip="tooltip"
    :disabled="disabled"
    :name="name"
    :validation="validation"
    :errorMessageKey="errorMessageKey"
  >
    <div
      class="relative w-full"
      :class="{
        'text-neutral600': disabled,
        'flex items-center': labelRight?.length,
      }"
    >
      <input
        :id="id"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        class="transition duration-200 text-base text-neutral1000 outline outline-neutral300 placeholder:text-neutral700 placeholder:font-normal placeholder:tracking-tight focus:outline-2 focus:enabled:outline-neutral1000 disabled:text-neutral600 input focusVisible"
        :class="{
          'outline outline-2 outline-red400': hasError,
          'hover:enabled:outline-neutral500': !hasError,
          'pl-12': icon || iconComponent,
          'rounded-full': rounded,
          'rounded-lg': !rounded,
          'mr-4': labelRight?.length,
          'w-full': !labelRight?.length,
          [background]: true,
          [SIZE_STYLES[size]]: true,
          [customClasses]: true,
        }"
        @input="handleInput"
        @blur="$emit('blur')"
      />
      <p class="h200" v-if="labelRight?.length">{{ labelRight }}</p>
      <i
        v-if="icon"
        :class="[icon, size]"
        class="absolute left-5 text-neutral700 input-icon"
      ></i>
      <iconComponent
        v-if="iconComponent"
        class="absolute left-5 text-neutral800 input-icon-component"
        :class="[size]"
      />
    </div>
  </InputWrapper>
</template>

<style lang="scss">
.input-icon {
  &.sm {
    top: 13px;
  }
  &.md {
    top: 22px;
  }
  &.lg {
    top: 25px;
  }
}

.input-icon-component {
  left: 12px;
  &.sm {
    top: 13px;
  }
  &.md {
    top: 17px;
  }
  &.lg {
    top: 22px;
  }
}
</style>
