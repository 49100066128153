import { graphqlClient } from "@/apollo";
import type { ProfileFragment } from "@/graphql";
import {
  type EmployeeInput,
  GetProfilesDocument,
  type GetProfilesQueryVariables,
  SaveProfileDocument,
} from "@/graphql/generated-types";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const save = async (employee: EmployeeInput) => {
  const response = await graphqlClient.query({
    query: SaveProfileDocument,
    variables: {
      employee,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveProfile?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.profile.save`,
    response.data.user?.saveProfile,
  );
};

const getProfiles = async (
  variables: GetProfilesQueryVariables,
): Promise<ProfileFragment[]> => {
  const response = await graphqlClient.query({
    query: GetProfilesDocument,
    fetchPolicy: "no-cache",
    variables,
  });

  if (response.error) {
    throw new SentryGraphQLError(`api.profile.getProfiles`);
  }

  return (response.data.profiles || []) as ProfileFragment[];
};

export default {
  save,
  getProfiles,
};
