import { graphqlClient } from "@/apollo";
import {
  type NotificationSettingsInput,
  SaveActivityNotificationDocumentDocument,
} from "@/graphql/generated-types";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

const save = async (settings: NotificationSettingsInput) => {
  const response = await graphqlClient.query({
    query: SaveActivityNotificationDocumentDocument,
    variables: {
      settings,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.saveNotificationSettings?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.notifications.save`,
    response.data.activity?.saveNotificationSettings,
  );
};

export default {
  save,
};
