import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client/core";
import { APP_API_URL } from "./consts";
import { useAuthStore } from "./stores/auth.module";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { sentryException } from "./sentry";

const IBG_API_URL = `${APP_API_URL}/graphql`;

// Cache implementation
const cache = new InMemoryCache();

const customFetch = async (uri: any, options: any) => {
  const authStore = useAuthStore();

  // Setting the instituion ID
  const url = new URL(uri);
  url.searchParams.append("institution_id", authStore.institutionId);

  const headers = new Headers();
  if (authStore.token) {
    headers.set("Authorization", authStore.token);
  }

  const response = await window.fetch(url, {
    ...options,
    headers,
  });

  if (response.status >= 400) {
    // Let's create additonal data for the GraphQL query
    const data = JSON.parse(options.body);
    // No need to send the parameters. It might contain sensitive data.
    delete data.variables;

    const error = new Error("GraphQL 4xx error.");
    sentryException(error, data);
    throw error;
  }

  return response;
};

// Create the graphql client
export const graphqlClient = new ApolloClient({
  cache,
  link: from([
    // Remove the typename from variables
    removeTypenameFromVariables(),
    createHttpLink({
      uri: IBG_API_URL,
      fetch: customFetch,
    }),
  ]),
});

export default { graphqlClient };
