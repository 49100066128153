import type { IDefaultResponse } from "@/interfaces";

/**
 * Use this exception to throw GraphQL errors. Some of the GraphQL responses
 * might have "DefaultResponse" type (see generated-types.ts file). In this case,
 * we have a chance to send more data to the sentry about the error such as
 * permission error, missing data, etc.
 *
 * If the API response doens't return the DefaultResponse type, you can skip the
 * it.
 *
 * @example throw new SentryGraphQLError(`api.user.save`, response.data.user?.saveUser);
 */
class SentryGraphQLError extends Error {
  constructor(message: string, data?: IDefaultResponse | null) {
    const apiError = data?.message || "Unkown";
    const fullMessage = `${message}: ${apiError}`;
    super(fullMessage);
  }
}

export default SentryGraphQLError;
