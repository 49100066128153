import { graphqlClient } from "@/apollo";
import {
  DeleteNewsDocument,
  GetNewsByFiltersDocument,
  NewsEntryDocument,
  type NewsInput,
  SaveNewsDocument,
} from "@/graphql/generated-types";
import { useAuthStore } from "@/stores/auth.module";
import SentryGraphQLError from "@/helpers/exceptions/SentryGraphQLError";

type VisibilityOption = "visibility-on" | "visibility-off";

const getNews = async (
  dateFrom: string,
  dateTo: string,
  visibility: VisibilityOption,
) => {
  const authStore = useAuthStore();

  const institutionIds = authStore.currentInstitution?.id
    ? [authStore.currentInstitution?.id]
    : [];

  const response = await graphqlClient.query({
    query: GetNewsByFiltersDocument,
    variables: {
      visibility: visibility === "visibility-on",
      dateFrom,
      dateTo,
      filter: {
        departments: [],
        groups: [],
        institutions: institutionIds,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.newsItem?.getNewsByFilters) {
    return response.data.newsItem.getNewsByFilters;
  }

  throw new SentryGraphQLError(`api.news.getNews`);
};

const getNewsDetail = async (id: string) => {
  const response = await graphqlClient.query({
    query: NewsEntryDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.newsEntry;
};

const save = async (news: NewsInput) => {
  const response = await graphqlClient.query({
    query: SaveNewsDocument,
    variables: {
      news,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.news?.save?.success) {
    return response;
  }

  throw new SentryGraphQLError(`api.news.save`, response.data.news?.save);
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteNewsDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.news?.delete?.success) {
    return response;
  }

  throw new SentryGraphQLError(
    `api.news.deleteItem`,
    response.data.news?.delete,
  );
};

export default {
  getNews,
  getNewsDetail,
  deleteItem,
  save,
};
