<script setup lang="ts">
import { computed } from "vue";
import { toRefs } from "vue";

interface Props {
  active?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
  disabled: false,
});

defineEmits(["click"]);

const { active, disabled } = toRefs(props);

type StyleSelectorTypes =
  | "on_enabled"
  | "on_disabled"
  | "off_enabled"
  | "off_disabled";

const selector = computed(() => {
  const activeSelector = active.value ? "on" : "off";
  const disabledSelector = disabled.value ? "disabled" : "enabled";
  return `${activeSelector}_${disabledSelector}` as StyleSelectorTypes;
});

const STYLE_MAP: Record<StyleSelectorTypes, string> = {
  on_enabled: "bg-darkOcean500 gradient focus:outline-neutral1000",
  on_disabled: "bg-darkOcean300 focus:outline-none",
  off_enabled: "bg-white border border-neutral700 focus:outline-neutral1000",
  off_disabled: "bg-white border border-neutral400 focus:outline-none",
};
</script>

<template>
  <button
    type="button"
    class="w-6 h-6 min-h-6 min-w-6 rounded-full flex justify-center items-center"
    :class="STYLE_MAP[selector]"
    @click="$emit('click')"
  >
    <div class="bg-white innerCircle"></div>
  </button>
</template>

<style lang="scss">
.gradient {
  background: linear-gradient(268.75deg, #00434e 2.18%, #186571 95.98%);
}

.innerCircle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
</style>
