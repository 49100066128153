import axios from "axios";
import apiclient from "@/services/apiclient";
import * as Sentry from "@sentry/vue";
import { useCommonStore, type IError } from "@/stores/common.module";

export default function setup() {
  const queryParams = new URLSearchParams(window.location.search);
  // Add a request interceptor
  apiclient.interceptors.request.use(
    (config) => {
      if (queryParams.get("institution_id") && !config.params?.institution_id) {
        config.params = {
          ...config.params,
          institution_id: queryParams.get("institution_id"),
        };
      }

      return config;
    },
    (error: any) => {
      console.error(error.request);
    },
  );
  apiclient.interceptors.response.use(
    (config: any) => {
      // Do something before request is sent
      return config;
    },
    (error: any) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const errorData: IError = {
        status: error.response?.status,
        url: error.response?.config?.url || error.config?.url,
        method: error.response?.config?.method || error.config?.method,
        code: error.response?.data?.error?.code,
        message:
          error.response?.data?.error?.message ||
          error.response?.data?.message ||
          error.message,
        correlationId:
          error.response?.data?.correlationId ||
          error.response?.headers["x-correlation-id"],
      };

      const { setError } = useCommonStore();
      setError(errorData);

      Sentry.withScope((scope: any) => {
        Object.keys(errorData).forEach((key: any) => {
          if (key !== "message") {
            scope.setExtra(key, (errorData as any)[key]);
          }
        });

        Sentry.captureException(new Error(`Planner API: ${errorData.message}`));
      });

      return Promise.reject(error);
    },
  );
}
