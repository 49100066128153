<script setup lang="ts">
import { onMounted, ref } from "vue";
import RadioButton from "../RadioButton/RadioButton.vue";
import { toRefs } from "vue";
import emitter from "@/stories/helpers/eventBus";

interface Props {
  icon?: string;
  radio?: boolean;
  isActive?: boolean;
  textColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  radio: false,
  isActive: false,
  textColor: "text-neutral800",
});

const emit = defineEmits(["click"]);
const { icon } = toRefs(props);
const teleportId = ref<string | null>(null);
const buttonRef = ref();
const slotRef = ref();

const handleClick = () => {
  emit("click");
  if (teleportId.value) {
    const data = {
      title: slotRef.value.innerHTML,
      icon: icon.value,
    };
    emitter.emit(`selectOptionGeneral:${teleportId.value}`, data);
  }
};

const waitForTheButton = () => {
  return new Promise((resolve: any) => {
    const interval = setInterval(() => {
      if (buttonRef?.value?.parentElement) {
        clearInterval(interval);
        resolve();
      }
    }, 10);
  });
};

onMounted(() => {
  waitForTheButton().then(() => {
    if (buttonRef?.value?.parentElement?.parentElement?.firstChild?.dataset) {
      teleportId.value =
        "#" +
        buttonRef?.value.parentElement.parentElement.firstChild.dataset
          .teleport;
    } else {
      teleportId.value = null;
    }
  });
});
</script>

<template>
  <button
    ref="buttonRef"
    type="button"
    @click="handleClick"
    class="b300 text-left px-2 py-3 transition-colors rounded-lg dropdown-link w-full"
    :class="{
      'flex gap-3 items-center': icon || radio,
      'justify-between': radio,
      'bg-neutral0 hover:bg-neutral50': !isActive,
      'bg-darkOcean100': isActive,
      [textColor]: true,
    }"
  >
    <i
      v-if="icon"
      :class="{ [icon]: true, [textColor]: [] }"
      class="min-w-5"
    ></i>
    <div class="whitespace-nowrap w-full truncate" ref="slotRef">
      <slot></slot>
    </div>
    <div v-if="radio" class="pl-10">
      <RadioButton :active="isActive" />
    </div>
  </button>
</template>
