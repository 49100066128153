import { type IValidationResult } from "@/interfaces";
import { computed, type Ref } from "vue";

export const useFieldValidationError = (
  name: Ref<string>,
  validation: Ref<IValidationResult | null | undefined>,
) => {
  const hasError = computed(() => {
    if (!validation.value) {
      return false;
    }

    return validation.value.errors[name.value];
  });

  return { hasError };
};
